/**
 * Enum for crosselling types.
 * @readonly
 * @enum type of crossSelling
 *  */
const CROSS_SELLING_TYPES = Object.freeze({
  WPC: 'wpc',
  VUE: 'vue',
  RR: 'rich_relevance',
  RM: 'retail_media'
});

export default CROSS_SELLING_TYPES;
